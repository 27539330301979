import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
// import { logging } from 'w3-user-ui-component';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import styles from './Interests.module.scss';
import Tag from './Tag';

const Interests = ({ id, className }: ICompDefault) => {
  const { interestsData } = useContext(ProfileStateContext);
  const [displayInterests, setDisplayInterests] = useState(false);

  useEffect(() => {
    if (interestsData && interestsData.visible && interestsData.tags.length > 0) {
      setDisplayInterests(true);
    }
  }, [interestsData]);

  if (displayInterests) {
    return (
      <SectionWrapper id={id} headerText='Interests' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        {interestsData!.tags.map((item) => (
          <Tag key={`tag-${item.tagId}`} tag={item} />
        ))}
      </SectionWrapper>
    );
  }

  return null;
};

export default Interests;
