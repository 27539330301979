/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable react/jsx-no-bind */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Modal as BModal } from 'react-bootstrap';

import Spinner, { SpinnerSize } from '../userlib/Spinner/Spinner';
import BackArrowButton from '../userlib/buttons/BackArrowButton';
import Button from '../userlib/buttons/Button';

import './Modal.scss';

/**
 * **Bootstrap 4.6** media breakpoints.
 *
 * Note from Bootstrap on these numbers:
 *
 * Note that since browsers do not currently support range context queries,
 * we work around the limitations of min- and max- prefixes and viewports with
 * fractional widths (which can occur under certain conditions on high-dpi devices, for instance)
 *  by using values with higher precision for these comparisons.
 */
export const BREAKPOINTS = {
  xsmall: 575.98,
  small: 767.98,
  medium: 991.98,
  large: 1199.98,
};

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'noframe' | 'pink' | 'dark';

export type ButtonElemType = 'a' | 'button';

interface IProp {
  /**
   * Specifies a unique id for an element
   */
  id?: string,
  /**
   * Specifies one or more classnames for an element (refers to a class in a style sheet)
   */
  className?: string,
  /**
   * Hide/show the modal
   * @default false
   */
  show: boolean,
  /**
   * Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size.
   * @default undefined
   */
  size?: 'sm' | 'lg' | 'xl' | undefined,
  /**
   * Include a backdrop component. Specify 'static' for a backdrop that doesn't trigger an "onHide" when clicked.
   * @default true
   */
  backdrop?: boolean | 'static' | undefined,
  /**
   * True if we are to display a close button, an x in the corner
   * @default true
   */
  closeButton?: boolean,
  /**
   * The header text
   * @default ''
   */
  title?: string | JSX.Element | React.ReactNode,
  /**
   * True if we are to display a Cancel button
   * @default true
   */
  displayCancel?: boolean,
  /**
   * True if we are to display a Ok button
   * @default true
   */
  displayOk?: boolean,
  /**
   * Text of the cancel button
   * @default 'Cancel'
   */
  cancelText?: string,
  /**
   * Text of the ok button
   * @default 'Ok'
   */
  okText?: string,
  /**
   * Variant of the ok button
   * @default 'primary'
   */
  okButtonVariant?: ButtonVariant,
  /**
   * Variant of the cancel button
   * @default 'tertiary'
   */
  cancelButtonVariant?: ButtonVariant,
  /**
   * The type of the ok button
   * @default 'button'
   */
  okButtonType?: ButtonElemType,
  /**
   * The type of the cancel button
   * @default 'button'
   */
  cancelButtonType?: ButtonElemType,
  /**
   * Extra component to be positioned to the left of the Cancel and Ok button
   */
  preExtraButtons?: JSX.Element,
  /**
   * Extra component to be positioned between the Cancel and Ok button
   */
  extraButtons?: JSX.Element,
  /**
   * Extra component to be positioned to the right of the Cancel and Ok button
   */
  postExtraButtons?: JSX.Element,
  /**
   * True if ok buttons should be disabled
   */
  disabled?: boolean,
  /**
   * True if loading animation on ok button should be displayed. Cancel button will be disabled when True.
   */
  loading?: boolean,
  /**
   * Allows scrolling the <Modal.Body> instead of the entire Modal when overflowing.
   */
  scrollable?: boolean,
  /**
   * Force the module to be full screen on medium sized viewports
   */
  forceFullScreenOnMedium?: boolean,
  /**
   * Handle when the modal is closed
   * @param e Event
   */
  handleClose?: (e?: any) => void,
  /**
   * Handle when the ok button is clicked
   * @param e Event
   */
  handleOk?: (e?: any) => void,
  /**
   * Handle when the modal is displayed
   * @param e Event
   */
  handleShow?: (e?: any) => void,
}

const Modal = ({
  id,
  children,
  className,
  show,
  size,
  backdrop,
  closeButton,
  title,
  displayCancel,
  displayOk,
  cancelText,
  okText,
  okButtonVariant,
  cancelButtonVariant,
  okButtonType,
  cancelButtonType,
  preExtraButtons,
  extraButtons,
  postExtraButtons,
  disabled,
  loading,
  scrollable,
  forceFullScreenOnMedium,
  handleClose,
  handleOk,
  handleShow,
}: React.PropsWithChildren<IProp>) => {
  const [closeBtn, setCloseBtn] = useState(true);

  const [okType, setOkType] = useState<ButtonElemType>('button');
  useEffect(() => {
    if (okButtonType !== undefined) {
      setOkType(okButtonType);
    }
  }, [okButtonType]);

  const [cancelType, setCancelType] = useState<ButtonElemType>('button');
  useEffect(() => {
    if (cancelButtonType !== undefined) {
      setCancelType(cancelButtonType);
    }
  }, [cancelButtonType]);

  const [okVariant, setOkVariant] = useState<ButtonVariant>('primary');
  useEffect(() => {
    if (okButtonVariant !== undefined) {
      setOkVariant(okButtonVariant);
    }
  }, [okButtonVariant]);

  const [cancelVariant, setCancelVariant] = useState<ButtonVariant>('tertiary');
  useEffect(() => {
    if (cancelButtonVariant !== undefined) {
      setCancelVariant(cancelButtonVariant);
    }
  }, [cancelButtonVariant]);

  const [modalBackdrop, setModalBackdrop] = useState<boolean | 'static'>(true);
  useEffect(() => {
    if (backdrop !== undefined) {
      setModalBackdrop(backdrop);
    } else {
      setModalBackdrop(true);
    }
  }, [backdrop]);

  const [modalSize, setModalSize] = useState<'sm' | 'lg' | 'xl' | undefined>();
  useEffect(() => {
    setModalSize(size);
  }, [size]);

  const [headerTitle, setHeaderTitle] = useState<string | JSX.Element | React.ReactNode>('');
  useEffect(() => {
    if (title !== undefined) {
      setHeaderTitle(title);
    } else {
      setHeaderTitle('');
    }
  }, [title]);

  const [cancelBtnText, setCancelBtnText] = useState('Cancel');
  useEffect(() => {
    if (cancelText) {
      setCancelBtnText(cancelText);
    }
  }, [cancelText]);

  const [okBtnText, setOkBtnText] = useState('Ok');
  useEffect(() => {
    if (okText) {
      setOkBtnText(okText);
    }
  }, [okText]);

  const [isScrollable, setIsScrollable] = useState<boolean>(true);
  useEffect(() => {
    if (scrollable !== undefined) {
      setIsScrollable(!!scrollable);
    }
  }, [scrollable]);

  const [display, setDisplay] = useState(false);
  useEffect(() => {
    if (show !== undefined) {
      setDisplay(show);
    } else {
      setDisplay(false);
    }
  }, [show]);

  const [displayCancelBtn, setDisplayCancelBtn] = useState(true);
  useEffect(() => {
    if (displayCancel !== undefined) {
      setDisplayCancelBtn(displayCancel);
    } else {
      setDisplayCancelBtn(true);
    }
  }, [displayCancel]);

  const [displayOkBtn, setDisplayOkBtn] = useState(true);
  useEffect(() => {
    if (displayOk !== undefined) {
      setDisplayOkBtn(displayOk);
    } else {
      setDisplayOkBtn(true);
    }
  }, [displayOk]);

  /**
   * Close the modal
   * @param e {Event}
   */
  function closeModal(e?: any) {
    setDisplay(false);

    if (handleClose) {
      handleClose(e);
    }
  }

  /**
   * Click the ok button
   * @param e {Event}
   */
  function onClickOk(e?: any) {
    if (handleOk) {
      handleOk(e);
    }
  }

  const checkIfFullScreen = useCallback(
    () => window.innerWidth < BREAKPOINTS.xsmall || (forceFullScreenOnMedium && window.innerWidth < BREAKPOINTS.small),
    [forceFullScreenOnMedium],
  );

  const setCloseButtonValue = useCallback(() => {
    if (closeButton !== undefined) {
      setCloseBtn(checkIfFullScreen() ? false : !!closeButton);
    } else {
      setCloseBtn(!checkIfFullScreen());
    }
  }, [checkIfFullScreen, closeButton]);

  useEffect(() => {
    setCloseButtonValue();
  }, [closeButton, setCloseButtonValue]);

  useEffect(() => {
    window.addEventListener('resize', setCloseButtonValue);

    return () => {
      window.removeEventListener('resize', setCloseButtonValue);
    };
  }, [setCloseButtonValue]);

  return (
    <BModal
      id={id}
      show={display}
      onHide={closeModal}
      onShow={handleShow}
      size={modalSize}
      className={`w3s-modal-modal${forceFullScreenOnMedium ? ' w3s-modal-fullscreen-md' : ''}${className ? ` ${className}` : ''}`}
      animation={false}
      scrollable={isScrollable}
      backdrop={checkIfFullScreen() ? 'static' : modalBackdrop}
      centered={!checkIfFullScreen()}
    >
      {checkIfFullScreen() && (
        <div className='modal-top-row'>
          <div className='modal-cancel-alternative'>
            <BackArrowButton text='' disabled={!!loading} handleOnClick={closeModal} />
          </div>

          {displayOkBtn && (
            <div className='modal-top-ok-group'>
              {!!loading && <Spinner size={SpinnerSize.sm} className='mr-2' />}

              <div
                className={
                  'modal-ok-alternative' +
                  ` modal-variant-${okVariant === 'primary' || okVariant === 'destructive' ? okVariant : 'primary'}${
                    !!disabled || !!loading ? ' disabled' : ''
                  }`
                }
                onClick={onClickOk}
              >
                {okText}
              </div>
            </div>
          )}
        </div>
      )}
      <BModal.Header closeButton={closeBtn && !loading}>
        <BModal.Title>{headerTitle}</BModal.Title>
      </BModal.Header>
      <BModal.Body>{children}</BModal.Body>
      <BModal.Footer>
        {!!preExtraButtons && <div className='modal-extra-buttons justify-content-start modal-full-width'>{preExtraButtons}</div>}

        {displayCancelBtn && (
          <div className='modal-cancel-button'>
            <Button variant={cancelVariant} as={cancelType} disabled={!!loading} text={cancelBtnText} onClick={closeModal} />
          </div>
        )}

        {!!extraButtons && <div className='modal-extra-buttons'>{extraButtons}</div>}

        {displayOkBtn && (
          <div className='modal-ok-button'>
            <Button variant={okVariant} as={okType} disabled={!!disabled || !!loading} text={okBtnText} loading={loading} onClick={onClickOk} />
          </div>
        )}

        {!!postExtraButtons && <div className='modal-extra-buttons justify-content-end'>{postExtraButtons}</div>}
      </BModal.Footer>
    </BModal>
  );
};

Modal.defaultProps = {
  id: undefined,
  className: undefined,
  size: undefined,
  backdrop: true,
  closeButton: true,
  title: '',
  displayCancel: true,
  displayOk: true,
  cancelText: 'Cancel',
  okText: 'Ok',
  okButtonVariant: 'primary',
  cancelButtonVariant: 'tertiary',
  okButtonType: 'button',
  cancelButtonType: 'button',
  preExtraButtons: undefined,
  extraButtons: undefined,
  postExtraButtons: undefined,
  disabled: false,
  loading: false,
  scrollable: false,
  forceFullScreenOnMedium: false,
  handleClose: undefined,
  handleOk: undefined,
  handleShow: undefined,
};

export default Modal;
