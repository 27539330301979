import React from 'react';

export interface ICheck {
  className?: string,
}

const Check = ({ className }: ICheck) => (
  <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg' className={className || ''}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.8541 0.145917C11.9007 0.192363 11.9376 0.247538 11.9629 0.308284C11.9881 0.369029 12.001 0.43415 12.001 0.499917C12.001 0.565685 11.9881 0.630806 11.9629 0.691551C11.9376 0.752296 11.9007 0.807472 11.8541 0.853917L4.85414 7.85392C4.8077 7.90048 4.75252 7.93742 4.69178 7.96263C4.63103 7.98784 4.56591 8.00081 4.50014 8.00081C4.43438 8.00081 4.36925 7.98784 4.30851 7.96263C4.24776 7.93742 4.19259 7.90048 4.14614 7.85392L0.646143 4.35392C0.552257 4.26003 0.499512 4.13269 0.499512 3.99992C0.499512 3.86714 0.552257 3.7398 0.646143 3.64592C0.74003 3.55203 0.867368 3.49929 1.00014 3.49929C1.13292 3.49929 1.26026 3.55203 1.35414 3.64592L4.50014 6.79292L11.1461 0.145917C11.1926 0.0993539 11.2478 0.0624112 11.3085 0.0372047C11.3693 0.0119983 11.4344 -0.000976563 11.5001 -0.000976562C11.5659 -0.000976562 11.631 0.0119983 11.6918 0.0372047C11.7525 0.0624112 11.8077 0.0993539 11.8541 0.145917Z'
      fill='#28A745'
    />
  </svg>
);

Check.defaultProps = {
  className: undefined,
};
export default Check;
