import React, { useMemo } from 'react';

import UtilFunctions from '../../../utils/functions';
import { SocialMediaTypes } from '../../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../../utils/interfaces/TypesInterfaces';

import styles from './MediaIcon.module.scss';

interface IMediaIcon extends ICompDefault {
  type: SocialMediaTypes,
  userName: string,
  openContactMe?: () => void,
}

const MediaIcon = ({ type, userName, className, openContactMe }: IMediaIcon) => {
  const url = useMemo(() => UtilFunctions.getSocialMediaUrl(userName, type), [type, userName]);
  const SocialIcon = UtilFunctions.getSocialMediaIcon(type);

  const onClick = (openModal: boolean) => {
    if (openModal && openContactMe) {
      openContactMe();
    } else if (!openModal) {
      window.open(url, '_blank', 'noreferrer');
    }
  };

  switch (type) {
    case SocialMediaTypes.Phone:
      return <SocialIcon className={`${styles.icon}${className ? ` ${className}` : ''}`} onClick={() => onClick(true)} />;
    default:
      return <SocialIcon className={`${styles.icon}${className ? ` ${className}` : ''}`} onClick={() => onClick(false)} />;
  }
};

MediaIcon.defaultProps = {
  openContactMe: undefined,
};

export default MediaIcon;
