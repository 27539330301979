export interface ICompDefault {
  /**
   * Any classes to be added to the component
   */
  className?: string,
  /**
   * Id of an html element
   */
  id?: string,
}

export enum MONTH {
  'January' = 0,
  'February' = 1,
  'March' = 2,
  'April' = 3,
  'May' = 4,
  'June' = 5,
  'July' = 6,
  'August' = 7,
  'September' = 8,
  'October' = 9,
  'November' = 10,
  'December' = 11,
}

export const TIMEOUT = 30000;
