import React, { useMemo } from 'react';

import W3BadgeIcon from '../../../assets/icons/W3BadgeIcon';
import { CertificateType } from '../../../utils/interfaces/DbInterfaces';
import {
  ICompDefault,
  MONTH,
} from '../../../utils/interfaces/TypesInterfaces';

import styles from './Certificate.module.scss';

interface ICertificateComp extends ICompDefault {
  data: CertificateType,
}

const Certificate = ({ data, className }: ICertificateComp) => {
  const dateCompleted = useMemo(() => {
    if (data.dateCompleted) {
      const myDate = new Date(data.dateCompleted);

      return `${myDate.getDate()} ${MONTH[myDate.getMonth()]}, ${myDate.getFullYear()}`;
    }

    return '';
  }, [data.dateCompleted]);

  return (
    <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.section_wrapper}>
        <W3BadgeIcon className={styles.badge} />
        <div>{data.title}</div>
      </div>
      <div className={styles.section_wrapper}>
        <div className={styles.completed_date}>{dateCompleted}</div>
        <a href={data.verifyUrl} target='_blank' className={styles.view} rel='noreferrer'>
          View
        </a>
      </div>
    </div>
  );
};

export default Certificate;
