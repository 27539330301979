/* eslint-disable react/no-array-index-key */
import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import styles from './Experience.module.scss';

type IExperienceProps = ICompDefault;

const Experience = ({ id, className }: IExperienceProps) => {
  const { experienceData } = useContext(ProfileStateContext);
  const [displayExperience, setDisplayExperience] = useState(false);

  useEffect(() => {
    if (experienceData && experienceData.visible && experienceData.experience.length > 0) {
      setDisplayExperience(true);
    }
  }, [experienceData]);

  if (displayExperience) {
    return (
      <SectionWrapper id={id} headerText='Experience' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        <div className={`${styles.experienceContainer}`}>
          {experienceData?.experience.map((exp, index) => (
            <div key={index} className={`${styles.experienceItem}`}>
              <div className={`${styles.experienceContent}`}>
                <div className={`${styles.experienceInfo}`}>
                  <h3 className={`${styles.experiencePosition}`}>{exp.position}</h3>
                  <p className={`${styles.experienceDetails}`}>
                    {exp.company} - {exp.location}
                  </p>
                  {exp.duties && Array.isArray(exp.duties) && (
                    <ul className={`${styles.experienceDuties}`}>
                      {exp.duties.map((duty, dutyIndex) => (
                        <li key={dutyIndex}>{duty}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <p className={`${styles.experienceDates}`}>
                  {exp.startDate} - {exp.endDate}
                </p>
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    );
  }

  return null;
};

export default Experience;
