import React from 'react';

import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import styles from './TextGrouping.module.scss';

interface ISection extends ICompDefault {
  title: string,
  value: string | number,
}

const Section = ({ title, value, className }: ISection) => (
  <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
    <div className={styles.title}>{title}</div>
    <div className={styles.value}>{value}</div>
  </div>
);

export default Section;
