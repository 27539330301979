/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/member-delimiter-style */

/* eslint-disable no-use-before-define */

/* eslint-disable @typescript-eslint/naming-convention */

export enum ComponentTypes {
  Interests = 'interests',
  Spaces = 'spaces',
  Achievements = 'achievements',
  Activity = 'activity',
  Discord = 'discord',
  Certificates = 'certificates',
  Skills = 'skills',
  Education = 'education',
  Experience = 'experience',
}

export enum SocialMediaTypes {
  Twitter = 'Twitter',
  LinkedIn = 'LinkedIn',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  GitHub = 'GitHub',
  Phone = 'Phone',
  Email = 'Email',
}

export interface IDefault {
  type: ComponentTypes;
  visible: boolean;
}

export interface ISocialMedia {
  type: SocialMediaTypes;
  userName: string;
  visible: boolean;
}

export interface IBasicSection {
  visible: boolean;
  value: string;
}
export interface IBasic {
  profilePicture?: string;
  firstName: string; // same as in settings/profile page
  lastName: string; // same as in settings/profile page
  aboutMe?: string;
  phone: IBasicSection;
  email: IBasicSection;
  website: IBasicSection;
  dateOfBirth?: string;
}

export type TagType = {
  tagId: string;
  tagName: string;
};

export type SpaceType = {
  spaceId: string;
  spaceUrl: string;
  pictureUrl?: string;
  description?: string;
  visible: boolean;
};

export type CertificateType = {
  id: string;
  verifyUrl: string;
  title: string;
  dateCompleted: number;
  visible: boolean;
};

export interface IInterests extends IDefault {
  tags: TagType[];
}

export interface ISpaces extends IDefault {
  spaces: SpaceType[];
}

export interface ICertificates extends IDefault {
  certificates: CertificateType[];
}

export interface IActivity extends IDefault {
  stars: number;
  lessonsRead: number;
  exercisePoints: number;
  quizPoints: number;
  totalPoints: number;
}

export interface IDiscord extends IDefault {
  user: string;
  role: string;
  messages: string;
  experience: string;
  level: number;
}

export interface IUserProfileConfiguration {
  id: string;
  userId: string;
  published: boolean;
  basic: IBasic;
  createdAt?: number;
  updatedAt?: number;
  socialMedias: ISocialMedia[];
  sections: (IDiscord | IActivity | ICertificates | ISpaces | IInterests | ISkill | IEducation | IExperience)[];
}

export interface IReportAbuse {
  name: string;
  email: string;
  profile: string;
  description: string;
}

export interface ISkill extends IDefault {
  skills: Skill[];
}

export interface Skill {
  id: string;
  name: string;
  level?: number;
  icon?: React.ElementType;
  visible?: boolean;
}

export interface IEducation extends IDefault {
  education: Education[];
}

export interface Education {
  degree?: string;
  period?: string;
  institution?: string;
  place?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
}

export interface IExperience extends IDefault {
  experience: Experience[];
}

export interface Experience {
  company?: string;
  period?: string;
  position?: string;
  location?: string;
  duties?: string[];
  dutiesString?: string;
  startDate?: string;
  endDate?: string;
}
