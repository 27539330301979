/* eslint-disable react/no-array-index-key */
import React from 'react';

import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import AchievementItem, { IAchievementItem } from './AchievementItem/AchievementItem';

import styles from './AchievmentsBar.module.scss';

export interface IAchievmentsBar extends ICompDefault {
  data: IAchievementItem[],
}

const AchievmentsBar = ({ data, className }: IAchievmentsBar) => {
  const clickOnItem = (event: React.BaseSyntheticEvent, item: IAchievementItem) => {
    if (item.onClick) {
      item.onClick(event, item.type);
    }
  };

  return (
    <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      {data.map((item: IAchievementItem, index: number) => (
        <AchievementItem
          key={`achievement-item-${index}`}
          className={styles.item}
          value={item.value}
          text={item.text}
          icon={item.icon}
          type={item.type}
          onClick={(event) => clickOnItem(event, item)}
        />
      ))}
    </div>
  );
};

AchievmentsBar.defaultProps = {};

export default AchievmentsBar;
