import React from 'react';

export interface IPencil {
  className?: string,
}

const Pencil = ({ className }: IPencil) => (
  <svg className={className || ''} width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13.4864 1.53836L12.4607 0.512695C12.1192 0.170898 11.6713 0 11.2234 0C10.7755 0 10.3276 0.170898 9.98581 0.512422L0.350418 10.1478L0.00315229 13.271C-0.0405977 13.6642 0.269207 14 0.653934 14C0.67827 14 0.702605 13.9986 0.727488 13.9959L3.8485 13.6511L13.4866 4.01297C14.1699 3.32965 14.1699 2.22168 13.4864 1.53836ZM3.44682 12.8155L0.903035 13.0974L1.18632 10.5498L8.40206 3.33402L10.6653 5.59727L3.44682 12.8155ZM12.8678 3.39445L11.2838 4.97848L9.02057 2.71523L10.6046 1.13121C10.7698 0.966055 10.9896 0.875 11.2234 0.875C11.4572 0.875 11.6767 0.966055 11.8422 1.13121L12.8678 2.15687C13.2088 2.49812 13.2088 3.0532 12.8678 3.39445Z'
      fill='#282A35'
    />
  </svg>
);

Pencil.defaultProps = {
  className: undefined,
};

export default Pencil;
