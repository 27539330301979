import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Discord as DiscordIcon } from 'react-bootstrap-icons';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import {
  ComponentTypes,
  IDiscord,
} from '../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';
import TextGrouping from '../TextGrouping';

import styles from './Discord.module.scss';

const DefaultData: IDiscord = {
  type: ComponentTypes.Discord,
  visible: true,
  user: '',
  role: '',
  messages: '0',
  experience: '0',
  level: 1,
};

type IDiscordProps = ICompDefault;

const Discord = ({ id, className }: IDiscordProps) => {
  const { discordData } = useContext(ProfileStateContext);
  const [data, setData] = useState<IDiscord>(DefaultData);

  useEffect(() => {
    if (discordData) {
      setData(discordData);
    }
  }, [discordData]);

  return (
    <SectionWrapper id={id} headerText='Discord' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <DiscordIcon className={styles.discord_icon} />
      <div className={styles.body_wrapper}>
        <div className={styles.group_wrapper}>
          <TextGrouping title='User' value={data.user} />
          <TextGrouping title='Role' value={data.role} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title='Messages' value={data.messages} />
          <TextGrouping title='Experience' value={data.experience} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title='Level' value={data.level} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Discord;
