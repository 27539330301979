import React from 'react';

import Check from '../../../assets/icons/userlib/Check';
import { ICompDefault } from '../../../utils/interfaces/TypesInterfaces';

import styles from './FormItem.module.scss';

export enum FormItemType {
  Input = 'input',
  Textarea = 'textarea',
}

interface IFormItem extends ICompDefault {
  title: string,
  type?: string,
  domType?: FormItemType,
  readOnly?: boolean,
  defaultValue?: string,
  required?: boolean,
  errorText?: string,
  displayError?: boolean,
  displayValidatedIcon?: boolean,
  onValueChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
}

const FormItem = ({
  title,
  type,
  domType,
  required,
  errorText,
  displayError,
  readOnly,
  displayValidatedIcon,
  defaultValue,
  onValueChange,
  id,
  className,
}: IFormItem) => (
  // const [value, setValue] = useState<string>('');

  <div id={id} className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
    <div className={styles.title}>{`${title}${required ? '*' : ''}`}</div>
    <div className={styles.input_wrapper}>
      {domType === FormItemType.Input && (
        <input
          className={`${styles.item}${readOnly ? ` ${styles.disabled}` : ''}${displayError ? ` ${styles.error}` : ''}`}
          readOnly={readOnly}
          required={required}
          defaultValue={defaultValue}
          type={type}
          onChange={onValueChange}
          maxLength={500}
          autoComplete='off'
          autoCapitalize='off'
        />
      )}

      {domType === FormItemType.Textarea && (
        <textarea
          readOnly={readOnly}
          required={required}
          className={`${styles.item}${readOnly ? ` ${styles.disabled}` : ''}${displayError ? ` ${styles.error}` : ''}`}
          onChange={onValueChange}
          maxLength={2000}
          autoComplete='off'
          autoCapitalize='sentences'
        />
      )}

      {displayValidatedIcon && <Check className={styles.check} />}
    </div>

    {displayError && <div className={styles.error_txt}>{errorText}</div>}
  </div>
);

FormItem.defaultProps = {
  domType: FormItemType.Input,
  type: 'text',
  readOnly: false,
  required: false,
  defaultValue: undefined,
  displayError: false,
  errorText: '',
  displayValidatedIcon: false,
  onValueChange: () => {},
};

export default FormItem;
