/* eslint-disable react/no-array-index-key */
import {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import styles from './Education.module.scss';

type IEducationProps = ICompDefault;

const Education = ({ id, className }: IEducationProps) => {
  const { educationData } = useContext(ProfileStateContext);
  const [displayEducation, setDisplayEducation] = useState(false);

  useEffect(() => {
    if (educationData && educationData.visible && educationData.education.length > 0) {
      setDisplayEducation(true);
    }
  }, [educationData]);

  if (displayEducation) {
    return (
      <SectionWrapper id={id} headerText='Education' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        <div className={`${styles.educationContainer}`}>
          {educationData?.education.map((edu, index) => (
            <div key={`${index}`} className={`${styles.educationItem}`}>
              <div className={`${styles.educationContent}`}>
                <div className={`${styles.educationInfo}`}>
                  <h3 className={`${styles.educationDegree}`}>{edu.degree}</h3>
                  <p className={`${styles.educationDetails}`}>
                    {edu.institution} - {edu.place}
                  </p>
                  <ul className={`${styles.educationDescription}`}>
                    <li>{edu.description}</li>
                  </ul>
                </div>
                <p className={`${styles.educationDates}`}>
                  {edu.startDate} - {edu.endDate}
                </p>
              </div>
            </div>
          ))}
        </div>
      </SectionWrapper>
    );
  }

  return null;
};

export default Education;
