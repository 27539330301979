import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import {
  util as W3Utils,
  fetchPublicProfile,
  logging,
} from 'w3-user-ui-component';

import SmileyIllustration from '../../assets/illustrations/SmileyIllustration';
import { NotificationStateContext } from '../../context/NotificationContext';
import { ProfileStateContext } from '../../context/ProfileDataContext';
import {
  nameValid,
  validateEmail,
} from '../../utils/functions/userlib/validations';
import { IReportAbuse } from '../../utils/interfaces/DbInterfaces';
import Notifications from '../Notifications';
import {
  NotificationDismissType,
  NotificationType,
} from '../Notifications/utils/TypesInterfaces';
import Button from '../userlib/buttons/Button';

import FormItem, { FormItemType } from './FormItem/FormItem';

import styles from './ReportAbuse.module.scss';

/**
 * The time in ms we are to wait before executing the timeout content.
 */
const WAIT = 400;

/**
 * Heading in notification when something has gone wrong
 */
const ERROR_HEADING = 'Something went wrong';

const initiateValidation = (event: React.ChangeEvent, timeoutId: NodeJS.Timeout | undefined) => {
  event.preventDefault();
  event.stopPropagation();

  // logging.logDebug('PublicProfile -> ReportAbuse -> initiateValidation -> event', event);

  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  return undefined;
};

const ReportAbuse = () => {
  const { abuseUrl, setAbuseUrl } = useContext(ProfileStateContext);
  const { notifications, dismissNotification, addNNotification } = useContext(NotificationStateContext);

  const [profileName, setProfileName] = useState('');
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [initiated, setInitiated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [nameErrorText, setNameErrorText] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [displayNameError, setDisplayNameError] = useState(false);
  const [valueName, setValueName] = useState('');

  const [emailErrorText, setEmailErrorText] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [displayEmailError, setDisplayEmailError] = useState(false);
  const [valueEmail, setValueEmail] = useState('');

  const [descriptionErrorText, setDescriptionErrorText] = useState('');
  const [isDescriptionValid, setIsDescriptionValid] = useState(false);
  const [displayDescriptionError, setDisplayDescriptionError] = useState(false);
  const [valueDescription, setValueDescription] = useState('');

  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const timeoutId = useRef<NodeJS.Timeout>();

  const navigate = useNavigate();

  useEffect(() => {
    if (abuseUrl && abuseUrl.charAt(0) === '/') {
      setProfileName(abuseUrl.substring(1));
    } else {
      setProfileName(abuseUrl || '');
    }
  }, [abuseUrl]);

  useEffect(() => {
    if (!isLoading && isNameValid && isEmailValid && valueDescription) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [isEmailValid, isLoading, isNameValid, valueDescription]);

  // hook for when page is loaded, scroll to top of page
  useEffect(() => {
    if (!initiated) {
      window.scrollTo(0, 0);
      setInitiated(true);
    }
  }, [initiated]);

  /**
   * Confirmation button -> return to profile page
   */
  const onClickConfirm = () => {
    setAbuseUrl(undefined);
    navigate(-1);
  };

  /**
   * Submits the form to the backend
   */
  const submitForm = async () => {
    setIsLoading(true);

    const myObject: IReportAbuse = {
      name: valueName,
      email: valueEmail,
      profile: `${W3Utils.getPublicProfileUrl()}/${profileName}`,
      description: valueDescription,
    };

    logging.logDebug('ProfileData -> submitForm -> myObject', myObject);

    try {
      const reportAbuseFetchRes = await fetchPublicProfile<any>({
        method: 'POST',
        url: '/report-abuse',
        timeout: 30000,
        authenticated: false,
        data: {
          name: myObject.name,
          emailId: myObject.email,
          profileUrl: myObject.profile,
          reason: myObject.description,
        },
      });

      if (reportAbuseFetchRes.error.code === '0') {
        setIsLoading(false);
        setDisplayConfirmation(true);
      } else {
        setDisplayConfirmation(false);

        const myNotif: NotificationType = {
          id: uuidv4(),
          dismissed: false,
          type: 'error',
          header: ERROR_HEADING,
          body: reportAbuseFetchRes.error.description || <div>Something went wrong submitting the report</div>,
        };

        addNNotification(myNotif);
        setIsLoading(false);
      }
    } catch (error) {
      setDisplayConfirmation(false);

      const myNotif: NotificationType = {
        id: uuidv4(),
        dismissed: false,
        type: 'error',
        header: ERROR_HEADING,
        body: (error as string) || <div>Something went wrong submitting the report</div>,
      };

      addNNotification(myNotif);
      setIsLoading(false);
    }
  };

  const formNameValueChanged = (event: React.ChangeEvent) => {
    timeoutId.current = initiateValidation(event, timeoutId.current);
    timeoutId.current = setTimeout(() => {
      const target = event.target as HTMLInputElement;
      const nameIsValid = nameValid(target.value);

      logging.logDebug('PublicProfile -> ReportAbuse -> formNameValueChanged, nameIsValid', nameIsValid);

      if (nameIsValid && !target.validity.valueMissing) {
        setNameErrorText('');
        setIsNameValid(true);
        setDisplayNameError(false);
      } else if (target.validity.valueMissing) {
        setNameErrorText('Missing value');
        setIsNameValid(false);
        setDisplayNameError(true);
      } else {
        setNameErrorText('Not a valid name');
        setIsNameValid(false);
        setDisplayNameError(true);
      }

      setValueName(target.value);
    }, WAIT);
  };

  const formEmailValueChanged = (event: React.ChangeEvent) => {
    timeoutId.current = initiateValidation(event, timeoutId.current);
    timeoutId.current = setTimeout(() => {
      const target = event.target as HTMLInputElement;

      if (validateEmail(target.value) && !target.validity.valueMissing) {
        setEmailErrorText('');
        setIsEmailValid(true);
        setDisplayEmailError(false);
      } else if (target.validity.valueMissing) {
        setEmailErrorText('Missing value');
        setIsEmailValid(false);
        setDisplayEmailError(true);
      } else {
        setEmailErrorText('Not a valid email pattern');
        setIsEmailValid(false);
        setDisplayEmailError(true);
      }

      setValueEmail(target.value);
    }, WAIT);
  };

  const formDescriptionValueChanged = (event: React.ChangeEvent) => {
    timeoutId.current = initiateValidation(event, timeoutId.current);
    timeoutId.current = setTimeout(() => {
      const target = event.target as HTMLTextAreaElement;

      if (target.validity.valueMissing) {
        setDescriptionErrorText('Missing value');
        setIsDescriptionValid(false);
        setDisplayDescriptionError(true);
      } else {
        setDescriptionErrorText('');
        setIsDescriptionValid(true);
        setDisplayDescriptionError(false);
      }

      setValueDescription(target.value);
    }, WAIT);
  };

  if (!abuseUrl) {
    return (
      <div className='PublicProfile-body'>
        <div className={styles.section_wrapper}>
          <div className={styles.header}>Report abuse</div>
          <div className={`${styles.description} ${styles.txt_recieved}`}>No user profile registered!</div>
          <Button variant='primary' text='Return' onClick={() => onClickConfirm()} />
        </div>
      </div>
    );
  }

  return (
    <div className={`PublicProfile-body ${styles.body}`}>
      {displayConfirmation ? (
        <div className={styles.section_wrapper}>
          <SmileyIllustration />
          <div className={styles.header}>Thank you!</div>
          <div className={`${styles.description} ${styles.txt_recieved}`}>We received your report</div>
          <Button variant='primary' text='Return' onClick={() => onClickConfirm()} />
        </div>
      ) : (
        <div className={styles.section_wrapper}>
          <div className={styles.header}>Report abuse</div>
          <div className={styles.description}>
            If you have come across an issue with a public profile published at W3schools, you are welcome to report it here.
          </div>

          <div className={styles.body_wrapper}>
            <form>
              <div className={styles.user_wrapper}>
                <FormItem
                  title='Name'
                  required
                  onValueChange={formNameValueChanged}
                  displayError={displayNameError}
                  displayValidatedIcon={isNameValid}
                  errorText={nameErrorText}
                  className={styles.user_item}
                />
                <FormItem
                  title='Email'
                  required
                  onValueChange={formEmailValueChanged}
                  displayError={displayEmailError}
                  displayValidatedIcon={isEmailValid}
                  errorText={emailErrorText}
                  className={styles.user_item}
                />
              </div>
              <FormItem title='ProfileURL' readOnly defaultValue={profileName} className={styles.form_item} />
              <FormItem
                title='What did you observe?'
                required
                onValueChange={formDescriptionValueChanged}
                domType={FormItemType.Textarea}
                displayError={displayDescriptionError}
                displayValidatedIcon={isDescriptionValid}
                errorText={descriptionErrorText}
                className={styles.form_item}
              />

              <Button
                loading={isLoading}
                disabled={isBtnDisabled}
                variant='primary'
                text='Report'
                className={styles.confirm_btn}
                onClick={submitForm}
              />
            </form>
          </div>
        </div>
      )}

      <Notifications
        className='PublicProfile-notifications'
        notifications={notifications}
        onClose={(notif: NotificationDismissType) => dismissNotification(notif.id)}
      />
    </div>
  );
};

export default ReportAbuse;
