import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import Social from '../Social';
import Button from '../userlib/buttons/Button';

import styles from './ContactFooter.module.scss';

interface IContactFooter extends ICompDefault {
  clickOpenContactModal: () => void,
}

const ContactFooter = ({ clickOpenContactModal, className }: IContactFooter) => {
  const { profileData } = useContext(ProfileStateContext);
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [disableContactMe, setDisableContactMe] = useState(false);

  // hook to check if any social media elements should be displayed
  useEffect(() => {
    if (profileData.socialMedias.length > 0) {
      const displayIndex = profileData.socialMedias.findIndex((item) => item.visible);
      setShowSocialMedia(displayIndex > -1);
    }
  }, [profileData.socialMedias]);

  // check if Contact Me button should be disabled
  useEffect(() => {
    setDisableContactMe(!(profileData.basic.email.visible || profileData.basic.phone.visible || profileData.basic.website.visible));
  }, [profileData.basic.email.visible, profileData.basic.phone.visible, profileData.basic.website.visible]);

  return (
    <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      {showSocialMedia && <Social openContactMe={() => clickOpenContactModal()} />}

      <div className={`${styles.contact_wrapper}${showSocialMedia ? ` ${styles.move_up}` : ''}`}>
        <Button
          disabled={disableContactMe}
          className={`${disableContactMe ? styles.disabled : ''}`}
          text='Contact Me!'
          variant='primary'
          onClick={() => clickOpenContactModal()}
        />
      </div>
    </div>
  );
};

export default ContactFooter;
