/* eslint-disable react/require-default-props */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { v4 as uuidv4 } from 'uuid';
import {
  Spinner,
  util as W3Util,
  fetchPublicProfile,
  logging,
} from 'w3-user-ui-component';

import Pencil from '../../assets/icons/userlib/Pencil';
import PencilFilled from '../../assets/icons/userlib/PencilFilled';
import { TIMEOUT } from '../../utils/interfaces/TypesInterfaces';
import { NotificationType } from '../Notifications/utils/TypesInterfaces';
import PopoverMenu, { IPopoverMenuChild } from '../PopoverMenu/PopoverMenu';

import styles from './ProfilePicture.module.scss';

export interface IProfilePicture {
  className?: string,
  /**
   * Function to be called when uploading a image
   */
  uploadedPicture?: (url: string) => void,
  /**
   * function to be called when deleting a image
   */
  deletedPicture?: (id?: string) => void,
  /**
   * Send a notification to display a notification toast
   * You will need to specify addNotification and updateNotification
   * for any of them to be called
   */
  addNotification?: (notification: NotificationType) => void,
  /**
   * Update a notification
   * You will need to specify addNotification and updateNotification
   * for any of them to be called
   */
  // updateNotification?: (notification: NotificationUpdateType) => void,
  /**
   * Set to true to be able to edit the profile picture
   */
  canEdit?: boolean,
  /**
   * The nick of the user to display the profile picture of
   */
  nick: string,
  /**
   * The first name of the user
   */
  firstName?: string,
  /**
   * The last name/family name of the user
   */
  lastName?: string,
  /**
   * Url of the image to be displayed.
   */
  srcURL?: string,
  /**
   * True if we are to fade in the picture
   */
  fadeIn?: boolean,
  disabled?: boolean,
  errorSavingPublicProfile?: (value: boolean, text: string) => void,
}

const ProfilePicture = ({
  className = undefined,
  srcURL = undefined,
  uploadedPicture,
  deletedPicture,
  addNotification,
  // updateNotification,
  errorSavingPublicProfile,
  canEdit = false,
  nick,
  firstName,
  lastName,
  fadeIn = false,
  disabled = false,
}: IProfilePicture) => {
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(canEdit || false);
  }, [canEdit]);

  const [imageURL, setImageURL] = useState<string | undefined>();
  useEffect(() => {
    setImageURL(srcURL ? `${W3Util.getPublicProfileUrl()}/${srcURL}?key=${new Date().getTime()}` : undefined);
  }, [srcURL]);

  const [hasPicture, setHasPicture] = useState(false);
  useEffect(() => {
    setHasPicture(!!imageURL);
  }, [imageURL]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(() => {
    // check for if hasPicture changes to true because then
    // the img element is drawn in the DOM and the loading of the image starts.
    if (hasPicture) {
      setLoadingImage(true);
    }
  }, [hasPicture]);

  /**
   * Is set to true if addNotification and updateNotification properties have values
   */
  const sendNotifications = useMemo<boolean>(() => !!addNotification /* && !!updateNotification */, [addNotification /* , updateNotification */]);

  const [zoomImage, setZoomImage] = useState<number>(0);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const [initials, setInitials] = useState<string | undefined>();
  useEffect(() => {
    if (firstName && lastName) {
      setInitials((firstName.charAt(0) + lastName.charAt(0)).toUpperCase());
    } else {
      setInitials(undefined);
    }
  }, [firstName, lastName]);

  const [triggerOffset, setTriggerOffset] = useState(-15);
  useEffect(() => {
    if (hasPicture) {
      if (errorLoadingImage) {
        setTriggerOffset(60);
      } else {
        setTriggerOffset(-27);
      }
    } else {
      setTriggerOffset(135);
    }
  }, [hasPicture, errorLoadingImage]);

  const notifIdRef = useRef<string | undefined>();
  const fileUploading = useRef<File | undefined>();

  /**
   * Upload the file to server
   * @param file The file to upload
   */
  const uploadFile = useCallback(
    async (file: File) => {
      logging.logDebug('ProfilePicture -> uploadFile : file', file);

      // if (sendNotifications) {
      notifIdRef.current = uuidv4();
      fileUploading.current = file;

      if (errorSavingPublicProfile) {
        errorSavingPublicProfile(false, '');
      }

      /* const notification: NotificationType = {
      id: notifIdRef.current,
      dismissed: false,
      autohide: false,
      closeButton: false,
      type: 'success',
      header: 'Uploading file',
      body: '',
      filename: `${file.name}`,
      showProgressBar: true,
      progressBarLevel: 0,
    };

    addNotification!(notification); */

      try {
        const pictureFetchRes = await fetchPublicProfile<any>({
          method: 'POST',
          url: `/user-profile/${nick}/upload-profile-picture`,
          cache: false,
          timeout: TIMEOUT,
          data: file,
          headers: {
            'Content-Type': 'image/*',
          },
        });

        logging.logDebug('ProfilePicture -> uploadFile -> pictureFetchRes', pictureFetchRes);

        if (pictureFetchRes.error.code === '0') {
          setImageURL(file.name);
          setLoadingImage(false);
          setIsDisabled(false);
          notifIdRef.current = undefined;
          fileUploading.current = undefined;

          if (uploadedPicture) uploadedPicture(file.name);
        } else {
          if (sendNotifications) {
            const notifId = uuidv4();
            const notifProp: NotificationType = {
              id: notifId,
              dismissed: false,
              autohide: false,
              closeButton: true,
              type: 'error',
              header: 'Uploading profile picture',
              body: pictureFetchRes.error.description || 'Something unexpected happened',
            };

            if (errorSavingPublicProfile) {
              errorSavingPublicProfile(true, pictureFetchRes.error.description || 'Something unexpected happened');
            }

            addNotification!(notifProp);
          }

          setLoadingImage(false);
          setIsDisabled(false);
          logging.logError('ProfilePicture -> error description', pictureFetchRes.error.description);
        }
      } catch (error) {
        if (sendNotifications) {
          const notifId = uuidv4();
          const notifProp: NotificationType = {
            id: notifId,
            dismissed: false,
            autohide: false,
            closeButton: true,
            type: 'error',
            header: 'Uploading profile picture',
            body: error as string,
          };

          if (errorSavingPublicProfile) {
            errorSavingPublicProfile(true, (error as string) || 'Something unexpected happened');
          }

          addNotification!(notifProp);
        }

        setLoadingImage(false);
        setIsDisabled(false);

        logging.logError('ProfilePicture -> error', error);
      }
    },
    [addNotification, errorSavingPublicProfile, nick, sendNotifications, uploadedPicture],
  );

  /**
   * Initiate the selection of the file dialog and call uploading of file.
   */
  const uploadImage = useCallback(() => {
    if (!disabled) {
      logging.logDebug('ProfilePicture -> uploadImage');
      const input: HTMLInputElement = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';

      input.onchange = () => {
        const file: File | null | undefined = input.files?.item(0);

        if (file) {
          setIsDisabled(true);
          setLoadingImage(true);
          uploadFile(file);
        }
      };

      input.click();
      input.remove();
    }
  }, [uploadFile, disabled]);

  /**
   * Delete image
   */
  const deleteImage = useCallback(async () => {
    if (hasPicture && !disabled) {
      logging.logDebug('ProfilePicture -> deleteImage');

      setImageURL(undefined);

      if (deletedPicture) deletedPicture();
    }
  }, [deletedPicture, disabled, hasPicture]);

  /**
   * The children to be displayed in the edit menu
   */
  const menuChildren = useMemo<IPopoverMenuChild[]>(
    () => [
      {
        action: uploadImage,
        title: 'Upload photo',
        disabled,
      },
      {
        action: deleteImage,
        title: 'Remove',
        redTitle: true,
        disabled: !hasPicture || disabled,
      },
    ],
    [uploadImage, deleteImage, hasPicture, disabled],
  );

  /**
   * When the profile image has loaded in the img dom element successfully
   *
   * @param event
   */
  const onPictureLoaded = (event: any) => {
    logging.logDebug('ProfilePicture -> onPictureLoaded: event', nick);
    setLoadingImage(false);

    const imageData = {
      zoom: {
        width: event.target.clientWidth / event.target.clientHeight,
        height: event.target.clientHeight / event.target.clientWidth,
      },
    };

    if (imageData.zoom.width > 1) {
      setZoomImage(imageData.zoom.width);
    } else if (imageData.zoom.height > 1) {
      setZoomImage(imageData.zoom.height);
    } else {
      setZoomImage(1);
    }
  };

  /**
   * When an error occurred loading the profile image into the img dom element
   *
   * @param event
   */
  const onErrorLoadingPicture = (event: any) => {
    logging.logDebug('ProfilePicture -> onErrorLoadingPicture: event', event);

    setLoadingImage(false);
    setErrorLoadingImage(true);
  };

  return (
    <div
      className={`${styles.profile_picture}${hasPicture ? ` ${styles.has_picture}` : ''}${loadingImage ? ` ${styles.loading}` : ''}${
        !editable ? ` ${styles.not_editable}` : ''
      }${disabled || isDisabled || loadingImage ? ` ${styles.disabled}` : ''}${className ? ` ${className}` : ''}`}
    >
      {hasPicture && (
        <div>
          {!errorLoadingImage && (
            <img
              onLoad={onPictureLoaded}
              onError={onErrorLoadingPicture}
              className={`${styles.image}${loadingImage ? ` ${styles.hide}` : ''}${fadeIn ? ` ${styles.fade_in}` : ''}`}
              src={imageURL}
              style={{ transform: `scale(${zoomImage})` }}
              alt='user'
            />
          )}

          {errorLoadingImage &&
            !loadingImage &&
            (editable ? (
              <div className={styles.error_text_loading_image}>Error loading image</div>
            ) : (
              <div className={styles.error_text_loading_image}>Error loading image</div>
            ))}
        </div>
      )}

      {loadingImage && <Spinner className={styles.image_spinner} size='medium' />}

      {!editable && !hasPicture && !errorLoadingImage && <div className={styles.profile_tmp_img}>{initials || '?'}</div>}

      {editable && !loadingImage && (
        <div>
          <div className={`${styles.hover_effect}${hasPicture ? ` ${styles.has_picture}` : ''}${errorLoadingImage ? ` ${styles.error}` : ''}`}>
            <div className={styles.edit_text}>Edit profile picture</div>
            <div className={`${styles.pencil}${isDisabled ? ` ${styles.disabled}` : ''}`}>
              <Pencil className={styles.pencil_default} />
              <PencilFilled className={styles.pencil_hover} />
            </div>
          </div>

          <PopoverMenu
            triggerElm={<div className={styles.trigger_layer} />}
            className={!hasPicture ? styles.popover : ''}
            childObjects={menuChildren}
            disabled={isDisabled || loadingImage || disabled}
            triggerOffset={triggerOffset}
          />
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
