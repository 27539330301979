import {
  Facebook as FacebookIcon,
  Github as GitHubIcon,
  Instagram as InstagramIcon,
  Linkedin as LinkedInIcon,
  SendFill as SendFillIcon,
  TelephoneFill as TelephoneFillIcon,
  Twitter as TwitterIcon,
} from 'react-bootstrap-icons';

import { SocialMediaTypes } from '../interfaces/DbInterfaces';

const getSocialMediaIcon = (type: SocialMediaTypes) => {
  switch (type) {
    case SocialMediaTypes.Facebook:
      return FacebookIcon;
    case SocialMediaTypes.Instagram:
      return InstagramIcon;
    case SocialMediaTypes.Twitter:
      return TwitterIcon;
    case SocialMediaTypes.GitHub:
      return GitHubIcon;
    case SocialMediaTypes.Phone:
      return TelephoneFillIcon;
    case SocialMediaTypes.Email:
      return SendFillIcon;
    default:
      return LinkedInIcon;
  }
};

export default getSocialMediaIcon;
