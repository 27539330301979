import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Discord as DiscordIcon } from 'react-bootstrap-icons';

import StarIcon from '../../assets/icons/StarIcon';
import W3BadgeIcon from '../../assets/icons/W3BadgeIcon';
import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ComponentTypes } from '../../utils/interfaces/DbInterfaces';
import {
  ICompDefault,
  MONTH,
} from '../../utils/interfaces/TypesInterfaces';
import AchievmentsBar from '../AchievmentsBar';
import { IAchievementItem } from '../AchievmentsBar/AchievementItem/AchievementItem';
import ProfilePicture from '../ProfilePicture';
import Social from '../Social';
import Button from '../userlib/buttons/Button';

import styles from './User.module.scss';

export interface IAchivementsDisplayed {
  certificates: boolean,
  achiements: boolean,
}
export interface IUser extends ICompDefault {
  openContactMeModal: (e?: React.BaseSyntheticEvent) => void,
  clickedAchievementItem: (type: ComponentTypes) => void,
}

const User = ({ className, clickedAchievementItem, openContactMeModal }: IUser) => {
  const { profileData, certificatesData, activityData, discordData, socialMediaData } = useContext(ProfileStateContext);
  const nick = useMemo(() => profileData.id, [profileData.id]);
  const description = useMemo(() => profileData.basic.aboutMe || '', [profileData.basic.aboutMe]);
  const [displayAchievements, setDisplayAchievements] = useState(false);

  const clickOnAchievementItem = useCallback(
    (event: React.BaseSyntheticEvent, type: ComponentTypes) => {
      event.preventDefault();
      event.stopPropagation();
      clickedAchievementItem(type);
    },
    [clickedAchievementItem],
  );

  const achievementData = useMemo<IAchievementItem[]>(() => {
    const myData: IAchievementItem[] = [];
    let achievementDisplay = false;

    if (certificatesData && certificatesData.visible && certificatesData.certificates.length > 0) {
      achievementDisplay = true;

      myData.push({
        value: certificatesData.certificates.length,
        text: 'Certificates',
        icon: <W3BadgeIcon className={styles.icon} />,
        type: ComponentTypes.Certificates,
        onClick: clickOnAchievementItem,
      });
    }

    if (activityData && activityData.visible) {
      achievementDisplay = true;
      myData.push({
        value: activityData.stars,
        text: 'Activity Stars',
        icon: <StarIcon className={styles.icon} />,
        type: ComponentTypes.Activity,
        onClick: clickOnAchievementItem,
      });
    }

    if (discordData && discordData.visible) {
      achievementDisplay = true;
      myData.push({
        text: discordData.role,
        icon: <DiscordIcon className={styles.icon} />,
        type: ComponentTypes.Discord,
        onClick: clickOnAchievementItem,
      });
    }

    setDisplayAchievements(achievementDisplay);

    return myData;
  }, [activityData, certificatesData, clickOnAchievementItem, discordData]);

  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [disableContactMe, setDisableContactMe] = useState(false);

  const memberSince = useMemo(() => {
    if (profileData.createdAt) {
      const myDate = new Date(profileData.createdAt * 1000);

      return `Member since ${MONTH[myDate.getMonth()]}, ${myDate.getFullYear()}`;
    }

    return '';
  }, [profileData.createdAt]);

  const formatDate = (dateString: string) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    const suffixes = ['st', 'nd', 'rd'];
    const dayInTens = day % 10;
    const suffix = dayInTens > 0 && dayInTens < 4 && (day < 10 || day > 20) ? suffixes[dayInTens - 1] : 'th';

    return `${day}${suffix} ${month}, ${year}`;
  };

  const formatedDOB = useMemo(() => {
    if (profileData.basic.dateOfBirth) {
      return formatDate(profileData.basic.dateOfBirth);
    }

    return '';
  }, [profileData.basic.dateOfBirth]);

  // check if Contact Me button should be disabled
  useEffect(() => {
    let visible = profileData.basic.email.visible || profileData.basic.phone.visible || profileData.basic.website.visible;

    if (!visible) {
      socialMediaData.forEach((item) => {
        if (item.visible) {
          visible = true;
        }
      });
    }

    setDisableContactMe(!visible);
  }, [profileData.basic.email.visible, profileData.basic.phone.visible, profileData.basic.website.visible, socialMediaData]);

  // hook to check if any social media elements should be displayed
  useEffect(() => {
    if (profileData.socialMedias.length > 0) {
      const displayIndex = profileData.socialMedias.findIndex((item) => item.visible);
      setShowSocialMedia(displayIndex > -1);
    }
  }, [profileData.socialMedias]);

  return (
    <div className={`${styles.user}${className ? ` ${className}` : ''}`}>
      <div className={`${styles.picture_wrapper}${displayAchievements ? ` ${styles.noAchievement}` : ''}}`}>
        <ProfilePicture
          firstName={profileData.basic.firstName}
          lastName={profileData.basic.lastName}
          nick={nick}
          className={`${styles.public_picture}`}
          srcURL={profileData.basic.profilePicture}
          fadeIn
        />
        {displayAchievements && <AchievmentsBar data={achievementData} />}
      </div>

      <div className={styles.user_name_wrapper}>
        <div className={styles.name_wrapper}>
          <div className={styles.name}>{`${profileData.basic.firstName} ${profileData.basic.lastName}`}</div>
        </div>
      </div>

      <div className={`${styles.description}`}>{description}</div>

      <div className={styles.user_info_wrapper}>
        <div className={styles.nick}>{`@${nick}`}</div>
        <div className={styles.member_since}>{memberSince}</div>
      </div>

      {formatedDOB && (
        <div className={styles.user_info_wrapper}>
          <div className={styles.nick}>DOB: {`${formatedDOB}`}</div>
        </div>
      )}

      {showSocialMedia && <Social openContactMe={() => openContactMeModal()} />}

      <div className={`${styles.contact_wrapper}${showSocialMedia ? ` ${styles.move_up}` : ''}`}>
        <Button
          disabled={disableContactMe}
          className={`${disableContactMe ? styles.disabled : ''}`}
          text='Contact Me!'
          variant='primary'
          onClick={openContactMeModal}
        />
      </div>
    </div>
  );
};

User.defaultProps = {};

export default User;
