import React from 'react';

export interface ISuccessIcon {
  className?: string,
}

const SuccessIcon = ({ className }: ISuccessIcon) => (
  <svg className={className || ''} width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23 11.5C23 14.55 21.7884 17.4751 19.6317 19.6317C17.4751 21.7884 14.55 23 11.5 23C8.45001 23 5.52494 21.7884 3.36827 19.6317C1.2116 17.4751 0 14.55 0 11.5C0 8.45001 1.2116 5.52494 3.36827 3.36827C5.52494 1.2116 8.45001 0 11.5 0C14.55 0 17.4751 1.2116 19.6317 3.36827C21.7884 5.52494 23 8.45001 23 11.5ZM17.2931 7.14437C17.1904 7.04205 17.0682 6.96149 16.9337 6.9075C16.7991 6.85351 16.6551 6.8272 16.5102 6.83015C16.3652 6.83311 16.2224 6.86526 16.0902 6.92468C15.9579 6.9841 15.8391 7.06958 15.7406 7.176L10.7482 13.5369L7.7395 10.5268C7.53512 10.3364 7.26481 10.2327 6.9855 10.2376C6.70619 10.2426 6.4397 10.3557 6.24217 10.5532C6.04464 10.7508 5.93149 11.0173 5.92656 11.2966C5.92163 11.5759 6.02531 11.8462 6.21575 12.0506L10.0194 15.8556C10.1218 15.9579 10.2439 16.0385 10.3782 16.0926C10.5124 16.1467 10.6563 16.1732 10.801 16.1705C10.9458 16.1678 11.0885 16.136 11.2207 16.077C11.3529 16.0179 11.4718 15.9329 11.5704 15.8269L17.3089 8.65375C17.5046 8.45034 17.6126 8.17833 17.61 7.89613C17.6073 7.61392 17.494 7.34402 17.2946 7.14437H17.2931Z'
      fill='#04AA6D'
    />
  </svg>
);

SuccessIcon.defaultProps = {
  className: undefined,
};

export default SuccessIcon;
