/* eslint-disable react/jsx-max-props-per-line */
import React, {
  useEffect,
  useState,
} from 'react';
import { HeartFill } from 'react-bootstrap-icons';

import { TagType } from '../../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../../utils/interfaces/TypesInterfaces';

import styles from './Tag.module.scss';

export interface ITag extends TagType {
  selected?: boolean,
}

interface ITagComp extends ICompDefault {
  tag: ITag,
}

const Tag = ({ tag, className }: ITagComp) => {
  const [displayHeart, setDisplayHeart] = useState(false);

  useEffect(() => {
    if (tag.tagName === 'W3Schools!') setDisplayHeart(true);
  }, [tag.tagName]);

  return (
    <div className={`${styles.tag_wrapper}${className ? ` ${className}` : ''}`}>
      {tag.tagName}
      {displayHeart && <HeartFill style={{ color: '#04aa6d' }} />}
    </div>
  );
};

export default Tag;
