import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import StarIcon from '../../assets/icons/StarIcon';
import { ProfileStateContext } from '../../context/ProfileDataContext';
import {
  ComponentTypes,
  IActivity,
} from '../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';
import TextGrouping from '../TextGrouping';

import styles from './Activity.module.scss';

const DefaultData: IActivity = {
  type: ComponentTypes.Activity,
  visible: true,
  stars: 0,
  lessonsRead: 0,
  exercisePoints: 0,
  quizPoints: 0,
  totalPoints: 0,
};

type IActivityProps = ICompDefault;

const Activity = ({ id, className }: IActivityProps) => {
  const { activityData } = useContext(ProfileStateContext);
  const [data, setData] = useState<IActivity>(DefaultData);

  useEffect(() => {
    if (activityData) {
      setData(activityData);
    }
  }, [activityData]);

  return (
    <SectionWrapper id={id} headerText='Activity Score' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <StarIcon className={styles.star_badge} />
      <div className={styles.body_wrapper}>
        <div className={styles.group_wrapper}>
          <TextGrouping title='Stars' value={data.stars} />
          <TextGrouping title='Lessons' value={data.lessonsRead} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title='Exercises' value={data.exercisePoints} />
          <TextGrouping title='Quizzes' value={data.quizPoints} />
        </div>

        <div className={styles.group_wrapper}>
          <TextGrouping title='Total' value={data.totalPoints} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Activity;
