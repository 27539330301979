import React, { useEffect } from 'react';

import styles from './Spinner.module.scss';

export enum SpinnerTypes {
  RoundEven = 1,
  RoundSlowStart = 2,
}

export enum SpinnerVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Destructive = 'destructive',
  Pink = 'pink',
  Dark = 'dark',
}

export enum SpinnerSize {
  sm = 'small',
  md = 'medium',
  lg = 'large',
  xl = 'xlarge',
}

interface ISpinnerProps {
  className?: string,
  type?: 1 | 2,
  variant?: 'primary' | 'secondary' | 'tertiary' | 'destructive' | 'pink' | 'dark',
  size?: 'small' | 'medium' | 'large' | 'xlarge',
}

const Spinner = ({ className, type = SpinnerTypes.RoundEven, variant = SpinnerVariants.Primary, size = SpinnerSize.md }: ISpinnerProps) => {
  const [classes, setClasses] = React.useState('');

  useEffect(() => {
    let returnValue = '';

    if (className) {
      returnValue = className;
    }

    switch (type) {
      case SpinnerTypes.RoundSlowStart:
        returnValue = `${returnValue} ${styles.lds_ring}`;
        break;
      default:
        // eslint-disable-next-line no-underscore-dangle
        returnValue = `${returnValue} ${styles._spinner_round_even}`;
    }

    const variantClass = `_spinner_color_${variant}`;
    returnValue = `${returnValue} ${styles[variantClass]}`;

    const sizeClass = `_spinner_size_${size}`;
    returnValue = `${returnValue} ${styles[sizeClass]}`;

    setClasses(returnValue);
  }, [className, size, type, variant]);

  switch (type) {
    case SpinnerTypes.RoundSlowStart:
      return (
        <div className={classes}>
          <div />
          <div />
          <div />
          <div />
        </div>
      );

    default: // SpinnerTypes.RoundEven
      return <div className={`${classes} w3s-spinner-override-speed`} />;
  }
};

Spinner.defaultProps = {
  className: undefined,
  type: 1,
  variant: 'primary',
  size: 'medium',
};

export default Spinner;
