import { SocialMediaTypes } from '../interfaces/DbInterfaces';

const getSocialMediaUrl = (userName: string, type: SocialMediaTypes) => {
  let myUrl = 'https://';

  switch (type) {
    case SocialMediaTypes.Twitter:
      myUrl += `twitter.com/${userName}`;
      break;
    case SocialMediaTypes.Facebook:
      if (Number.isNaN(Number(userName))) {
        myUrl += `facebook.com/${userName}`;
      } else {
        myUrl += `facebook.com/profile.php?id=${userName}`;
      }

      break;
    case SocialMediaTypes.Instagram:
      myUrl += `instagram.com/${userName}`;
      break;
    case SocialMediaTypes.GitHub:
      myUrl += `github.com/${userName}/`;
      break;
    case SocialMediaTypes.Email:
      myUrl = `mailto:${userName}`;
      break;
    case SocialMediaTypes.LinkedIn:
      myUrl += `www.linkedin.com/in/${userName}/`;
      break;
    default:
      myUrl = '';
  }

  return myUrl;
};

export default getSocialMediaUrl;
