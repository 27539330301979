import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { util as W3Util } from 'w3-user-ui-component';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import styles from './Spaces.module.scss';

type ISpacesProps = ICompDefault;

const Spaces = ({ id, className }: ISpacesProps) => {
  const { spaceData } = useContext(ProfileStateContext);
  const [displaySpaces, setDisplaySpaces] = useState(false);
  const nrOfSpaces = useMemo(() => spaceData?.spaces.length || 0, [spaceData?.spaces.length]);

  useEffect(() => {
    if (spaceData && spaceData.visible && spaceData.spaces.length > 0) {
      setDisplaySpaces(true);
    }
  }, [spaceData]);

  if (displaySpaces) {
    return (
      <SectionWrapper id={id} headerText='Spaces' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        {spaceData?.spaces.map((space) => {
          if (space.visible) {
            return (
              <div className={`${styles.space_wrapper}${nrOfSpaces === 1 ? ` ${styles.single_space}` : ''}`} key={`space-${space.spaceId}`}>
                {space.pictureUrl && (
                  <img
                    src={`${W3Util.getPublicProfileUrl()}/${space.pictureUrl}?key=${new Date().getTime()}`}
                    alt='Screenshot of the users website'
                    className={styles.picture}
                  />
                )}

                {!space.pictureUrl && <div className={styles.no_pic}>No preview</div>}

                <div className={styles.description_wrapper}>
                  <div className={styles.description}>{space.description}</div>
                  <a href={space.spaceUrl} className={styles.view} target='_blank' rel='noreferrer'>
                    View
                  </a>
                </div>
              </div>
            );
          }

          return undefined;
        })}
      </SectionWrapper>
    );
  }

  return null;
};

export default Spaces;
