import {
  ComponentTypes,
  IUserProfileConfiguration,
  SocialMediaTypes,
} from '../utils/interfaces/DbInterfaces';

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_DATA: IUserProfileConfiguration = {
  id: '',
  userId: '',
  createdAt: 1656504039,
  updatedAt: 1656504039,
  basic: {
    firstName: '',
    lastName: '',
    aboutMe: undefined,
    profilePicture: undefined,
    phone: { value: '', visible: false },
    email: { value: '', visible: false },
    website: { value: '', visible: false },
  },
  socialMedias: [
    {
      type: SocialMediaTypes.Email,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.Phone,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.LinkedIn,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.Twitter,
      userName: '',
      visible: false,
    },
    {
      type: SocialMediaTypes.GitHub,
      userName: '',
      visible: false,
    },
  ],
  sections: [
    {
      type: ComponentTypes.Activity,
      visible: false,
      stars: 0,
      lessonsRead: 0,
      exercisePoints: 0,
      quizPoints: 0,
      totalPoints: 0,
    },
    {
      type: ComponentTypes.Discord,
      visible: false,
      user: '',
      role: '',
      messages: '',
      experience: '',
      level: 0,
    },
    {
      type: ComponentTypes.Certificates,
      visible: false,
      certificates: [],
    },
    {
      type: ComponentTypes.Spaces,
      visible: false,
      spaces: [],
    },
    {
      type: ComponentTypes.Interests,
      visible: false,
      tags: [],
    },
  ],
  published: false,
};
