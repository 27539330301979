/* eslint-disable react/no-array-index-key */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button } from 'react-bootstrap';
import {
  Arrow,
  useLayer,
} from 'react-laag';

import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import styles from './PopoverMenu.module.scss';

export interface IPopoverMenuChild {
  /**
   * Id to seperate each item
   */
  id?: string,
  /**
   * The title to be displayed in the menu
   */
  title: string,
  /**
   * Set to true if menu text should be red
   */
  redTitle?: boolean,
  /**
   * The action to be triggered on click
   */
  action: (id?: string) => void,
  /**
   * Any classnames one want to add to the child element
   */
  className?: string,
  /**
   * Any classes you want to add to the divider that is displayed after this element
   */
  dividerClassName?: string,
  /**
   * Set to true to disable this child element
   */
  disabled?: boolean,
}

interface IPopoverMenuPropsType {
  triggerElm: React.ReactElement,
  childObjects: IPopoverMenuChild[],
  disabled?: boolean,
  /**
   * Distanse the menu keeps as offset to the trigger.
   * @default 12
   */
  triggerOffset?: number,
  className?: string,
}

const PopoverMenu = ({ triggerElm, childObjects, disabled, triggerOffset = 12, className }: IPopoverMenuPropsType) => {
  const [isOpen, setOpen] = React.useState(false);

  // helper function to close the menu
  function close() {
    setOpen(false);
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: true, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'bottom-center', // we prefer to place the menu 'top-end'
    triggerOffset, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  const clickMenuItem = (event: any, child: IPopoverMenuChild) => {
    event.stopPropagation();
    if (!disabled) child.action(child.id);
  };

  // Again, we're using framer-motion for the transition effect
  return (
    <div>
      <div
        className={`${className ? ` ${className}` : ''}`}
        onClick={(event) => {
          event.stopPropagation();
          setOpen(!isOpen && !disabled);
        }}
        {...triggerProps}
      >
        {triggerElm}
      </div>

      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.div className={styles.popover_menu_wrp} {...layerProps}>
              {childObjects.map((child: IPopoverMenuChild, index: number) => (
                <div key={`menuitem-${index}`}>
                  <Button
                    className={`${styles.menu_item}${child.redTitle ? ` ${styles.red_text}` : ''}${child.disabled ? ` ${styles.disabled}` : ''}${
                      child.className ? ` ${child.className}` : ''
                    }`}
                    onClick={(event: any) => clickMenuItem(event, child)}
                  >
                    {child.title}
                  </Button>

                  {index < childObjects.length - 1 && (
                    <div className={`${styles.divider}${child.dividerClassName ? ` ${child.dividerClassName}` : ''}`} />
                  )}
                </div>
              ))}

              <Arrow borderWidth={1} borderColor='#E7E9EB' {...arrowProps} />
            </motion.div>
          )}
        </AnimatePresence>,
      )}
    </div>
  );
};

PopoverMenu.defaultProps = {
  disabled: false,
  triggerOffset: 12,
  className: undefined,
};

export default PopoverMenu;
