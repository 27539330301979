import React, {
  useEffect,
  useState,
} from 'react';
import { Toast } from 'react-bootstrap';

import { logging } from 'w3-user-ui-component';

import Notification from '../Notification';
import NotificationIcon from '../NotificationIcon';
import { NotificationType } from '../utils/TypesInterfaces';

import styles from './NotificationToast.module.scss';

export interface INotificationToast {
  notifications: NotificationType | NotificationType[],
  onClose: (notification: NotificationType) => void,
  className?: string,
}

const NotificationToast = ({ notifications, onClose, className }: INotificationToast) => {
  const [notificationList, setNotificationList] = useState<NotificationType[]>([]);
  useEffect(() => {
    if (Array.isArray(notifications)) {
      setNotificationList(notifications);
    } else {
      setNotificationList([notifications]);
    }
  }, [notifications]);

  const [mostSignificantNotification, setMostSignificantNotification] = useState<NotificationType>();
  useEffect(() => {
    const inProgress = notificationList.filter(
      (notification: NotificationType) => notification.showProgressBar && notification.progressBarLevel !== 100,
    );

    if (inProgress.length > 0) {
      setMostSignificantNotification(inProgress[0]);
    } else {
      const error = notificationList.filter((notification: NotificationType) => notification.type === 'error');

      if (error.length > 0) {
        setMostSignificantNotification(error[0]);
      } else {
        setMostSignificantNotification(notificationList[0]);
      }
    }
  }, [notificationList]);

  const [show, setShow] = useState(true);
  useEffect(() => {
    if (mostSignificantNotification) {
      setShow(!mostSignificantNotification.dismissed);
    }
  }, [mostSignificantNotification]);

  const [fadeOut, setFadeOut] = useState(false);

  const closeToast = (event: React.MouseEvent | React.KeyboardEvent | undefined) => {
    logging.logDebug('NotificationToast -> closeToast: event', event);
    /* event?.preventDefault();
        event?.stopPropagation(); */
    setFadeOut(true);
  };

  const onAnimationEnd = (event: React.AnimationEvent) => {
    logging.logDebug('NotificationToast -> onAnimationEnd: event', event);

    if (event && event.animationName.indexOf('animateIn') > -1) {
      logging.logDebug('NotificationToast -> onAnimationEnd: animateIn');
    } else {
      logging.logDebug('NotificationToast -> onAnimationEnd: animateOut');
      onClose(mostSignificantNotification!);
      setShow(false);
      setFadeOut(false);
    }
  };

  return (
    <div>
      {!!mostSignificantNotification && (
        <Toast
          show={show}
          onClose={closeToast}
          delay={mostSignificantNotification.delay ?? 5000}
          autohide={!(mostSignificantNotification.autohide === false || mostSignificantNotification.type === 'error')}
          className={`notification-toast-wrapper ${fadeOut ? ` ${styles.animate_out}` : ` ${styles.animate_in}`}${className ? ` ${className}` : ''}`}
          onAnimationEnd={onAnimationEnd}
        >
          <Toast.Header closeButton={mostSignificantNotification.closeButton ?? true}>
            <span className={styles.icon}>
              <NotificationIcon
                type={mostSignificantNotification.type}
                progressBarLevel={mostSignificantNotification.progressBarLevel}
                showProgressBar={mostSignificantNotification.showProgressBar}
              />
            </span>

            <span className={styles.text}>{mostSignificantNotification.header}</span>
          </Toast.Header>

          <Toast.Body>
            {notificationList.map((notification: NotificationType, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Notification notification={notification} key={`${notification.id} _${index}`} />
            ))}
          </Toast.Body>
        </Toast>
      )}
    </div>
  );
};

NotificationToast.defaultProps = {
  className: undefined,
};
export default NotificationToast;
