import React, {
  useEffect,
  useState,
} from 'react';

import ErrorIcon from '../../../assets/icons/userlib/Error';
import SuccessIcon from '../../../assets/icons/userlib/SuccessIcon';
import Spinner, {
  SpinnerSize,
  SpinnerVariants,
} from '../../userlib/Spinner/Spinner';

import styles from './NotificationIcon.module.scss';

export interface INotificationIcon {
  type: string,
  showProgressBar: boolean | undefined,
  progressBarLevel: number | undefined,
}

const NotificationIcon = ({ type, showProgressBar, progressBarLevel }: INotificationIcon) => {
  const [displayIcon, setDisplayIcon] = useState<React.ReactElement>();
  useEffect(() => {
    if (type === 'error') {
      setDisplayIcon(<ErrorIcon />);
    } else if (showProgressBar && progressBarLevel !== 100) {
      setDisplayIcon(<Spinner size={SpinnerSize.md} variant={SpinnerVariants.Dark} className={styles.spinner} />);
    } else {
      setDisplayIcon(<SuccessIcon />);
    }
  }, [type, showProgressBar, progressBarLevel]);

  return <div>{displayIcon}</div>;
};

export default NotificationIcon;
