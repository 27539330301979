import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Globe as GlobeIcon,
  SendFill as SendFillIcon,
  TelephoneFill as TelephoneFillIcon,
} from 'react-bootstrap-icons';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import UtilFunctions from '../../utils/functions';
import { SocialMediaTypes } from '../../utils/interfaces/DbInterfaces';
import Modal from '../Modal';
import ProfilePicture from '../ProfilePicture';

import styles from './ContactModal.module.scss';

interface IContactModal {
  show: boolean,
  className?: string,
  handleClose?: () => void,
}

const ContactModal = ({ show, className, handleClose }: IContactModal) => {
  const { profileData } = useContext(ProfileStateContext);
  const [websiteUrl, setWebsiteUrl] = useState<string>('');
  const [displayEmail, setDisplayEmail] = useState(false);
  const [displayPhone, setDisplayPhone] = useState(false);

  const nick = useMemo(() => profileData.id, [profileData.id]);

  useEffect(() => {
    setDisplayEmail(profileData.basic.email && profileData.basic.email.visible);
  }, [profileData.basic.email, profileData.basic.email.visible]);

  useEffect(() => {
    setDisplayPhone(profileData.basic.phone && profileData.basic.phone.visible);
  }, [profileData.basic.phone, profileData.basic.phone.visible]);

  useEffect(() => {
    if (profileData.basic.website) {
      const myUrl: string = profileData.basic.website.value;
      let index = myUrl.indexOf('http');
      let myNewUrl = '';

      if (index === 0) {
        index = myUrl.indexOf('http://');

        if (index === 0) {
          // all ok
        } else {
          index = myUrl.indexOf('https://');

          if (index === 0) {
            // all ok
          } else {
            myNewUrl = 'https://';
          }
        }
      } else {
        myNewUrl = 'https://';
      }

      myNewUrl = `${myNewUrl}${profileData.basic.website.value}`;

      setWebsiteUrl(myNewUrl);
    }
  }, [profileData.basic.website, profileData.basic.website.value]);

  const closeModule = () => {
    if (handleClose) handleClose();
  };

  return (
    <Modal
      show={show}
      size='lg'
      title='Contact Me!'
      displayOk={false}
      displayCancel={false}
      cancelText='Close'
      scrollable
      handleClose={closeModule}
      className={`${className ? `${className}` : ''}`}
    >
      <div className={`${styles.modal_wrapper}`}>
        <div className={styles.name_wrapper}>
          <ProfilePicture
            firstName={profileData.basic.firstName}
            lastName={profileData.basic.lastName}
            nick={nick}
            className={styles.public_picture}
            srcURL={profileData.basic.profilePicture}
          />
          <div className={styles.name}>
            {profileData.basic.firstName} {profileData.basic.lastName}
          </div>
        </div>

        {displayEmail && (
          <div className={styles.section}>
            <div className={styles.header_wrapper}>
              <SendFillIcon />
              <div className={styles.sub_header}>Email</div>
            </div>
            <a href={`mailto:${profileData.basic.email.value}`} target='_blank' rel='noreferrer' className={styles.content_txt}>
              {profileData.basic.email.value}
            </a>
          </div>
        )}

        {displayPhone && (
          <div className={styles.section}>
            <div className={styles.header_wrapper}>
              <TelephoneFillIcon />
              <div className={styles.sub_header}>Phone</div>
            </div>
            <div className={styles.content_txt}>{profileData.basic.phone.value}</div>
          </div>
        )}

        {profileData.socialMedias.map((item) => {
          if (item.visible && item.type !== SocialMediaTypes.Email && item.type !== SocialMediaTypes.Phone) {
            const url = UtilFunctions.getSocialMediaUrl(item.userName, item.type);
            const Icon = UtilFunctions.getSocialMediaIcon(item.type);

            return (
              <div key={`sm-${item.type.toLowerCase()}`} className={styles.section}>
                <div className={styles.header_wrapper}>
                  <Icon />
                  <div className={styles.sub_header}>{item.type}</div>
                </div>
                <a href={url} target='_blank' className={styles.content_txt} rel='noreferrer'>
                  {url}
                </a>
              </div>
            );
          }

          return undefined;
        })}

        {profileData.basic.website.visible && (
          <div className={styles.section}>
            <div className={styles.header_wrapper}>
              <GlobeIcon />
              <div className={styles.sub_header}>Website</div>
            </div>
            <a href={websiteUrl} target='_blank' className={styles.content_txt} rel='noreferrer'>
              {profileData.basic.website.value}
            </a>
          </div>
        )}
      </div>
    </Modal>
  );
};

ContactModal.defaultProps = {
  handleClose: undefined,
  className: undefined,
};

export default ContactModal;
