/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import { ComponentTypes } from '../../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../../utils/interfaces/TypesInterfaces';

import styles from './AchievementItem.module.scss';

export interface IAchievementItem extends ICompDefault {
  value?: string | number,
  text: string,
  icon: React.ReactElement,
  type: ComponentTypes,
  onClick?: (event: React.BaseSyntheticEvent, type: ComponentTypes) => void,
}

const AchievementItem = ({ text, value, icon, onClick, type, className }: IAchievementItem) => {
  const clickOnItem = (event: React.BaseSyntheticEvent, itemType: ComponentTypes) => {
    if (onClick) {
      onClick(event, itemType);
    }
  };

  return (
    <div
      className={`${styles.wrapper}${onClick ? ` ${styles.display_cursor}` : ''}${className ? ` ${className}` : ''}`}
      onClick={(event) => clickOnItem(event, type)}
    >
      <div className={styles.icon_wrapper}>{icon}</div>
      <div className={`${styles.text_wrapper}`}>
        {value && <div>{value}</div>}
        <div className={styles.text}>{text}</div>
      </div>
    </div>
  );
};

AchievementItem.defaultProps = {
  onClick: () => {},
  value: undefined,
};
export default AchievementItem;
