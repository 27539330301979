import React from 'react';

import { ICompDefault } from '../../../utils/interfaces/TypesInterfaces';

import styles from './SectionHeader.module.scss';

interface ISectionHeader extends ICompDefault {
  text: string,
}

const SectionHeader = ({ text, className }: ISectionHeader) => (
  <div className={`${styles.header_wrapper}${className ? ` ${className}` : ''}`}>{text}</div>
);

export default SectionHeader;
