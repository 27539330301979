import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  NotificationType,
  NotificationUpdateType,
} from '../components/Notifications/utils/TypesInterfaces';

export type NotificationContextState = {
  notifications: NotificationType[],
  addNNotification: (notification: NotificationType) => void,
  updateNotification: (updatedNotification: NotificationUpdateType) => void,
  dismissNotification: (notificationId: string) => void,
  getUploadNotifications: () => void,
  getActiveNotifications: () => void,
  dismissAllNotifications: () => void,
};

const DEFAULT_STATE: NotificationContextState = {
  notifications: [],
  addNNotification: () => {},
  updateNotification: () => {},
  dismissNotification: () => {},
  getUploadNotifications: () => {},
  getActiveNotifications: () => {},
  dismissAllNotifications: () => {},
};

const NotificationStateContext = createContext<NotificationContextState>(DEFAULT_STATE);

const NotificationStateProvider = ({ children }: React.PropsWithChildren<any>) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  /**
   * Add a notification to the notification list
   * @param notification
   */
  const addNNotification = useCallback(
    (notification: NotificationType) => {
      setNotifications([...notifications, notification]);
    },
    [notifications],
  );

  /**
   * Update a notification
   * @param updatedNotification Object containing the id and the updated properties
   */
  const updateNotification = useCallback(
    (updatedNotification: NotificationUpdateType) => {
      const currentNotif = notifications.find((notif: NotificationType) => notif.id === updatedNotification.id);

      if (currentNotif) {
        const newNotif: NotificationType = { ...currentNotif, ...updatedNotification };

        setNotifications(
          notifications.map((notif: NotificationType) => {
            if (notif.id === newNotif.id) {
              return newNotif;
            }

            return notif;
          }),
        );
      }
    },
    [notifications],
  );

  /**
   * Dismiss a notification
   * @param notificationId The id of the notification to dismiss
   */
  const dismissNotification = useCallback(
    (notificationId: string) => {
      const currentNotif = notifications.find((notif: NotificationType) => notif.id === notificationId);

      if (currentNotif) {
        currentNotif.dismissed = true;
        setNotifications(
          notifications.map((n: NotificationType) => {
            if (n.id === currentNotif.id) {
              return currentNotif;
            }

            return n;
          }),
        );
      }
    },
    [notifications],
  );

  const dismissAllNotifications = useCallback(() => {
    setNotifications(
      notifications.map((n: NotificationType) => {
        const notif = { ...n };
        notif.dismissed = true;
        return notif;
      }),
    );
  }, [notifications]);

  /**
   * Get a list of upload-notifications
   */
  const getUploadNotifications = useCallback(
    (): NotificationType[] => notifications.filter((notif: NotificationType) => notif.showProgressBar && !notif.dismissed),
    [notifications],
  );

  /**
   * Get a list of active notifications
   * @param notificationList List of notifications
   * @returns
   */
  const getActiveNotifications = useCallback(
    (): NotificationType[] => notifications.filter((notif: NotificationType) => !notif.dismissed),
    [notifications],
  );

  const value: NotificationContextState = useMemo(
    () => ({
      notifications,
      addNNotification,
      updateNotification,
      dismissNotification,
      getUploadNotifications,
      getActiveNotifications,
      dismissAllNotifications,
    }),
    [
      addNNotification,
      dismissAllNotifications,
      dismissNotification,
      getActiveNotifications,
      getUploadNotifications,
      notifications,
      updateNotification,
    ],
  );

  return <NotificationStateContext.Provider value={value}>{children}</NotificationStateContext.Provider>;
};

export { NotificationStateProvider, NotificationStateContext };
