import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';
import SectionWrapper from '../SectionWrapper';

import Certificate from './Certificate';
import styles from './Certificates.module.scss';

const Certificates = ({ className, id }: ICompDefault) => {
  const { certificatesData } = useContext(ProfileStateContext);
  const [displayCertificates, setDisplayCertificates] = useState(false);

  useEffect(() => {
    if (certificatesData && certificatesData.visible && certificatesData.certificates.length > 0) {
      setDisplayCertificates(true);
    }
  }, [certificatesData]);

  if (displayCertificates) {
    return (
      <SectionWrapper id={id} headerText='Certificates' className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
        {certificatesData!.certificates.map((item) => {
          if (item.visible) {
            return <Certificate key={`tag-${item.id}`} data={item} />;
          }

          return undefined;
        })}
      </SectionWrapper>
    );
  }

  return null;
};

export default Certificates;
