import React from 'react';

interface IUploadSuccess {
  className?: string,
}

const UploadSuccess = ({ className }: IUploadSuccess) => (
  <svg className={className || ''} width='12' height='18' viewBox='0 0 12 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.3667 8.31444C11.2641 8.21212 11.1418 8.13156 11.0073 8.07757C10.8727 8.02358 10.7287 7.99727 10.5838 8.00022C10.4388 8.00318 10.296 8.03533 10.1638 8.09475C10.0315 8.15417 9.91266 8.23965 9.81423 8.34607L4.82179 14.707L1.81311 11.6969C1.60873 11.5064 1.33841 11.4028 1.05911 11.4077C0.779798 11.4126 0.513306 11.5258 0.315775 11.7233C0.118244 11.9208 0.00509603 12.1873 0.000167969 12.4666C-0.00476009 12.7459 0.0989163 13.0163 0.289356 13.2206L4.09298 17.0257C4.19545 17.128 4.31747 17.2086 4.45176 17.2627C4.58605 17.3168 4.72986 17.3433 4.87462 17.3406C5.01937 17.3379 5.1621 17.3061 5.2943 17.2471C5.42649 17.188 5.54544 17.103 5.64404 16.9969L11.3825 9.82382C11.5782 9.62041 11.6863 9.3484 11.6836 9.0662C11.6809 8.78399 11.5676 8.51409 11.3682 8.31444H11.3667Z'
      fill='#04AA6D'
    />
  </svg>
);

UploadSuccess.defaultProps = {
  className: undefined,
};

export default UploadSuccess;
