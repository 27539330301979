import React from 'react';

import { GoogleAnalytics } from 'w3-user-ui-component';

import W3Logo from '../../../../assets/icons/userlib/W3Logo';

interface IW3LogoButtonProps {
  loggedIn: boolean,
  className?: string,
  href?: string,
}

const W3LogoButton = ({ loggedIn, className, href }: IW3LogoButtonProps) => (
  <a
    href={href}
    onClick={() =>
      GoogleAnalytics.sendEvent({
        name: `Clicked on logo in topbar ${loggedIn ? 'when logged in' : 'anonymously'}`,
        category: loggedIn ? 'Topbar after login' : 'Topbar anonymously',
      })
    }
    className={className || ''}
  >
    <W3Logo />
  </a>
);

W3LogoButton.defaultProps = {
  className: undefined,
  href: '#',
};

export default W3LogoButton;
