import React from 'react';

import { util as W3util } from 'w3-user-ui-component';

import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import styles from './Banner.module.scss';

interface IBanner extends ICompDefault {
  mRef?: React.Ref<HTMLDivElement> | null,
}

const Banner = ({ className, mRef }: IBanner) => (
  <div ref={mRef || null} className={`${styles.banner_wrapper}${className ? ` ${className}` : ''}`}>
    <div>Get your own Public Profile</div>
    <a href={`${W3util.getLoginUrl()}?redirect_url=${encodeURIComponent(W3util.getProfileSettingsUrl())}`} className={styles.banner_btn}>
      <div>Go!</div>
    </a>
  </div>
);

Banner.defaultProps = {
  mRef: undefined,
};
export default Banner;
