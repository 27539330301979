export type EnvType = 'prod' | 'stage' | 'dev';

export const getEnv = (): EnvType => {
  if (typeof window !== 'undefined') {
    const host = window.location.hostname;

    switch (host) {
      case 'w3profile-dev.com':
      case 'www.w3profile-dev.com':
      case 'www.profile-dev.com':
      case 'w3profile-local.com':
      case 'localhost':
      case '127.0.0.1':
        return 'dev';
      case 'w3profile-stage.com':
      case 'www.w3profile-stage.com':
        return 'stage';
      default:
        return 'prod';
    }
  }

  return 'prod';
};

/**
 * Get the public profile base url
 * @param myEnv OPTIONAL! Current environment
 * @returns
 */
export const getPublicProfileUrl = (myEnv?: EnvType): string => {
  const environment = myEnv || getEnv();

  switch (environment) {
    case 'dev':
      return 'https://www.w3profile-dev.com';
    case 'stage':
      return 'https://www.w3profile-stage.com';
    default:
      return 'https://www.w3profile.com';
  }
};

export const getPathfinderApiUrl = (myEnv?: EnvType): string => {
  const environment = myEnv || getEnv();

  switch (environment) {
    case 'dev':
      return 'https://pathfinder-api.kai.w3sdevelop.com';
    case 'stage':
      return 'https://pathfinder-api.kai.w3stages.com';
    default:
      return 'https://pathfinder-api.kai.w3spaces.com';
  }
};
