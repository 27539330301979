import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { logging } from 'w3-user-ui-component';

import {
  ComponentTypes,
  IActivity,
  ICertificates,
  IDefault,
  IDiscord,
  IEducation,
  IExperience,
  IInterests,
  ISkill,
  ISocialMedia,
  ISpaces,
  IUserProfileConfiguration,
  TagType,
} from '../utils/interfaces/DbInterfaces';

import { DEFAULT_DATA } from './DefaultData';

export const tagsSort = (item1: TagType, item2: TagType) => {
  if (item1.tagName > item2.tagName) return 1;
  if (item1.tagName < item2.tagName) return -1;
  return 0;
};

export type ProfileContextState = {
  profileData: IUserProfileConfiguration,
  interestsData?: IInterests,
  certificatesData?: ICertificates,
  activityData?: IActivity,
  discordData?: IDiscord,
  socialMediaData: ISocialMedia[],
  spaceData?: ISpaces,
  skillData?: ISkill,
  educationData?: IEducation,
  experienceData?: IExperience,
  abuseUrl?: string,
  setAbuseUrl: (value: string | undefined) => void,
  setProfileData: (data: IUserProfileConfiguration) => void,
};

const DEFAULT_STATE: ProfileContextState = {
  profileData: DEFAULT_DATA,
  interestsData: undefined,
  certificatesData: undefined,
  activityData: undefined,
  discordData: undefined,
  spaceData: undefined,
  skillData: undefined,
  educationData: undefined,
  experienceData: undefined,
  socialMediaData: [],
  abuseUrl: undefined,
  setAbuseUrl: () => {},
  setProfileData: () => {},
};

const ProfileStateContext = createContext<ProfileContextState>(DEFAULT_STATE);

const ProfileStateProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [profileData, setProfileDataObject] = useState<IUserProfileConfiguration>(DEFAULT_DATA);
  const [interestsData, setInterestsData] = useState<IInterests>();
  const [certificatesData, setCertificatesData] = useState<ICertificates>();
  const [activityData, setActivityData] = useState<IActivity>();
  const [discordData, setDiscordData] = useState<IDiscord>();
  const [spaceData, setSpaceData] = useState<ISpaces>();
  const [educationData, setEducationData] = useState<IEducation>();
  const [experienceData, setExperienceData] = useState<IExperience>();
  const [abuseUrl, setAbuseUrl] = useState<string>();
  const [socialMediaData, setSocialMediaData] = useState<ISocialMedia[]>([]);

  const setProfileData = useCallback(
    (data: IUserProfileConfiguration) => {
      logging.logDebug('ProfileDataContext -> setProfileData -> data', data);
      const { sections } = profileData;

      sections.forEach((section) => {
        const index = data.sections.findIndex((item) => item.type === section.type);

        if (index === -1) {
          data.sections.push(section);
        } else if (section.type === ComponentTypes.Spaces) {
          (section as ISpaces).spaces.forEach((space) => {
            const urlIndex = space.spaceUrl.indexOf('http');

            if (urlIndex === -1) {
              // eslint-disable-next-line no-param-reassign
              space.spaceUrl = `https://${space.spaceUrl}`;
            }
          });
        }
      });

      const basic = { ...profileData.basic, ...data.basic };
      // eslint-disable-next-line no-param-reassign
      data.basic = basic;

      const { socialMedias } = profileData;

      if (socialMedias) {
        const mediaData = [...socialMedias];

        mediaData.forEach((item, index) => {
          const dataIndex = data.socialMedias.findIndex((social) => social.type === item.type);

          if (dataIndex > -1) {
            // eslint-disable-next-line no-param-reassign
            mediaData[index] = { ...item, ...data.socialMedias[dataIndex] };
          }
        });

        // eslint-disable-next-line no-param-reassign
        data.socialMedias = mediaData;
      }

      setProfileDataObject(data);
    },
    [profileData],
  );

  useEffect(() => {
    const { socialMedias } = profileData;

    if (socialMedias) {
      setSocialMediaData(socialMedias);
    } else {
      setSocialMediaData([]);
    }
  }, [profileData, profileData.socialMedias]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const interestsIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Interests);

      if (interestsIndex > -1) {
        const interests = sections[interestsIndex] as IInterests;
        interests.tags.sort(tagsSort);

        setInterestsData(interests);
      } else {
        setInterestsData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const certificatesIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Certificates);

      if (certificatesIndex > -1) {
        setCertificatesData(sections[certificatesIndex] as ICertificates);
      } else {
        setCertificatesData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const activitiesIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Activity);

      if (activitiesIndex > -1) {
        setActivityData(sections[activitiesIndex] as IActivity);
      } else {
        setActivityData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const discordIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Discord);

      if (discordIndex > -1) {
        setDiscordData(sections[discordIndex] as IDiscord);
      } else {
        setDiscordData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const spacesIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Spaces);

      if (spacesIndex > -1) {
        setSpaceData(sections[spacesIndex] as ISpaces);
      } else {
        setSpaceData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const educationIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Education);

      if (educationIndex > -1) {
        setEducationData(sections[educationIndex] as IEducation);
      } else {
        setEducationData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  useEffect(() => {
    const { sections } = profileData;

    if (sections) {
      const experienceIndex = sections.findIndex((item: IDefault) => item.type === ComponentTypes.Experience);

      if (experienceIndex > -1) {
        setExperienceData(sections[experienceIndex] as IExperience);
      } else {
        setExperienceData(undefined);
      }
    }
  }, [profileData, profileData.sections]);

  const value: ProfileContextState = useMemo(
    () => ({
      profileData,
      interestsData,
      certificatesData,
      activityData,
      discordData,
      socialMediaData,
      spaceData,
      abuseUrl,
      setProfileData,
      setAbuseUrl,
      educationData,
      experienceData,
    }),
    [
      abuseUrl,
      activityData,
      certificatesData,
      discordData,
      interestsData,
      profileData,
      setProfileData,
      socialMediaData,
      spaceData,
      educationData,
      experienceData,
    ],
  );

  return <ProfileStateContext.Provider value={value}>{children}</ProfileStateContext.Provider>;
};

export { ProfileStateProvider, ProfileStateContext };
