import React, {
  useContext,
  useState,
} from 'react';

import { ProfileStateContext } from '../../context/ProfileDataContext';
import { ISocialMedia } from '../../utils/interfaces/DbInterfaces';
import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import MediaIcon from './MediaIcon';
import styles from './Social.module.scss';

interface ISocial extends ICompDefault {
  openContactMe?: () => void,
}

const Social = ({ openContactMe, className }: ISocial) => {
  const { socialMediaData } = useContext(ProfileStateContext);
  const [socialType, setSocialType] = useState<string>(' ');

  return (
    <div className={`${styles.wrapper}${className ? ` ${className}` : ''}`}>
      <div className={styles.badges_wrapper}>
        {socialMediaData.map((item: ISocialMedia) =>
          item.visible === true ? (
            <div key={`social-${item.type.toLowerCase()}`} onMouseEnter={() => setSocialType(item.type)} onMouseLeave={() => setSocialType(' ')}>
              <MediaIcon type={item.type} userName={item.userName} openContactMe={openContactMe} />
            </div>
          ) : null,
        )}
      </div>
      <div className={styles.type_text}>{socialType}</div>
    </div>
  );
};

Social.defaultProps = {
  openContactMe: undefined,
};

export default Social;
