import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import * as CSS from 'csstype';

/**
 * Set position of a page footer div
 * @param ref Reference to the div element that is parent to the div to set position
 * @param deps Hook dependencies to trigger the useEffect hook
 * @return Return true if footer is set to fixed
 */
const useFooterPosition = (ref: React.RefObject<HTMLDivElement>, deps: any[]): boolean => {
  const [position, setPosition] = useState<CSS.Property.Position>();
  const [isPositionSet, setIsPositionSet] = useState(false);

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line no-param-reassign
      ref.current.style.position = position || 'relative';
    }
  }, [position, ref]);

  /**
   * Set position depending on document body height
   */
  const getPosition = useCallback(() => {
    const { clientHeight, scrollHeight } = document.body;
    setPosition(scrollHeight - clientHeight > 0 ? 'relative' : 'fixed');
    setIsPositionSet(!!ref.current && scrollHeight - clientHeight <= 0);
  }, [ref]);

  useEffect(() => {
    window.addEventListener('resize', getPosition);
    getPosition();

    return () => {
      window.removeEventListener('resize', getPosition);
    };
  }, [getPosition, deps]);

  return isPositionSet;
};

export default useFooterPosition;
