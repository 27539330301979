import React from 'react';

export interface IPencilFilled {
  className?: string,
}

const PencilFilled = ({ className }: IPencilFilled) => (
  <svg className={className || ''} width='14' height='14' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.11066 1.6391L7.36102 3.88945L2.47447 8.77599L0.468106 8.99748C0.199512 9.02718 -0.0274217 8.80007 0.00246111 8.53148L0.225703 6.52371L5.11066 1.6391V1.6391ZM8.75285 1.30406L7.69623 0.247437C7.36664 -0.0821533 6.83209 -0.0821533 6.5025 0.247437L5.50846 1.24148L7.75881 3.49183L8.75285 2.49779C9.08244 2.16802 9.08244 1.63365 8.75285 1.30406V1.30406Z'
      fill='#282A35'
    />
  </svg>
);

PencilFilled.defaultProps = {
  className: undefined,
};

export default PencilFilled;
