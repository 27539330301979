import React from 'react';

import { ICompDefault } from '../../utils/interfaces/TypesInterfaces';

import SectionHeader from './SectionHeader';
import styles from './SectionWrapper.module.scss';

interface ISectionWrapper extends ICompDefault {
  headerText: string,
}

const SectionWrapper = ({ headerText, children, className, id }: React.PropsWithChildren<ISectionWrapper>) => (
  <div id={id} className={styles.wrapper}>
    <SectionHeader text={headerText} />
    <div className={`${className ? ` ${className}` : ''}`}>{children}</div>
  </div>
);

export default SectionWrapper;
