import React from 'react';

import Spinner, {
  SpinnerSize,
  SpinnerTypes,
} from '../Spinner/Spinner';

import './LoadingBox.module.scss';

interface ILoadingBoxPropsType {
  text?: string,
  textPlacement?: 'top' | 'bottom',
  position?: 'relative' | 'absolute' | 'fixed',
  topDisplacement?: string,
  size?: SpinnerSize,
  spinnerType?: SpinnerTypes,
}

const LoadingBox = ({ text, textPlacement, position, topDisplacement, size, spinnerType }: ILoadingBoxPropsType) => (
  <div
    className='loading-box'
    style={{
      position,
    }}
  >
    <div className='-inner-wrp'>
      <div
        className='-inner-wrp'
        style={{
          top: topDisplacement,
        }}
      >
        {textPlacement === 'top' && <div className='-title'>{text}</div>}

        <Spinner className='-spinner' size={size} type={spinnerType} />

        {textPlacement === 'bottom' && <div className='-title'>{text}</div>}
      </div>
    </div>
  </div>
);

LoadingBox.defaultProps = {
  text: 'Loading...',
  textPlacement: 'top',
  position: 'relative',
  topDisplacement: '-10%',
  size: SpinnerSize.lg,
  spinnerType: SpinnerTypes.RoundEven,
};

export default LoadingBox;
